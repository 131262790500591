import styled, { css, keyframes } from 'styled-components';

import { hexToRgba, getThemeColor } from '@utils/styled';

const slowFadeIn = keyframes`
  0%,
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoaderStyled = styled.div`
  position: relative;
  width: 2rem;
  height: 2rem;
  border-width: calc(2rem * 0.15);
  border-style: solid;
  border-color: ${({ theme, $color }) =>
    hexToRgba($color || getThemeColor('bluegrey.60')({ theme }), 0.2)};
  border-radius: 50%;
  border-top-color: ${({ theme, $color }) => $color || getThemeColor('hue.green')({ theme })};
  animation: ${rotate} 600ms linear infinite;
  ${({ spacingTop, theme }) => spacingTop && theme.getFluidSpacing('margin-top', ...spacingTop)};

  ${({ delayShow }) =>
    delayShow &&
    css`
      opacity: 0;
      animation:
        ${slowFadeIn} 800ms linear forwards,
        ${rotate} 600ms linear infinite;
    `}

  ${({ isLarge }) =>
    isLarge &&
    css`
      width: 6.4rem;
      height: 6.4rem;
      border-width: calc(6.4rem * 0.15);
    `};

  ${({ size }) =>
    size &&
    css`
      width: ${size}rem;
      height: ${size}rem;
      border-width: calc(${size}rem * 0.15);
    `};
`;
